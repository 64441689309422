import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import PlatformUtils from 'Utils/platformUtils';

import AccordionContentSection from './accordionContentSection';
import Conditional from './conditional';
import HtmlSection from './htmlSection';

const SummaryWrapper = styled.div`
	#pre-data {
		margin-bottom: 2.5rem;
	}

	#pre-data-mobile {
		margin-bottom: 1.875rem;
	}
`;

type Props = {
	sectionMaxHeights?: number;
	content?: string;
	disableTranslate?: boolean;
	trackSectionViews?: (...args: any[]) => any;
	trackSectionClick?: (...args: any[]) => any;
	isAccordionPanelOpen?: boolean;
	sectionLabel?: string;
	size?: string;
};

class Summary extends React.PureComponent<Props> {
	constructor(props: Props) {
		super(props);
		(this as any).formattedData = this.formatData(this.props.content);
	}

	formatData = (summary: any) => {
		const match = summary.match(/<h2>(.*?)<\/h2>/g);
		if (!match) {
			return {
				preData: summary,
				data: [],
			};
		}
		const result = match.map((val: any) => val);
		const data = [];
		let preData = '';
		for (let i = 0; i < result.length; i++) {
			const position = summary.indexOf(result[i]);
			if (i === 0) {
				if (position === 0) {
					preData = '';
				} else {
					preData = summary.substr(0, position);
				}
			}
			// Logic to find content and headers
			const obj = { content: '', title: '' };
			obj.title = result[i];
			if (i === result.length - 1) {
				obj.content = summary.substring(
					position + result[i].length,
					summary.length,
				);
			} else {
				obj.content = summary.substring(
					position + result[i].length,
					summary.indexOf(result[i + 1]),
				);
			}
			data.push(obj);
		}

		return { preData, data };
	};

	render() {
		const { preData, data } = (this as any).formattedData;
		const {
			disableTranslate,
			trackSectionClick,
			trackSectionViews,
			isAccordionPanelOpen,
			sectionLabel,
			size,
		} = this.props;
		const isDesktop = PlatformUtils.isDesktop();
		const headings = data?.map(
			(d: any) => d.title?.match(/<h2>(.*?)<\/h2>/)[1],
		);

		return (
			<SummaryWrapper>
				{preData ? (
					<HtmlSection
						// @ts-expect-error TS(2322): Type '{ id: string; htmlContent: any; disableTrans... Remove this comment to see the full error message
						id={isDesktop ? 'pre-data' : 'pre-data-mobile'}
						htmlContent={preData}
						disableTranslate={disableTranslate}
					/>
				) : null}

				<Conditional if={headings}>
					{/* @ts-expect-error TS(7031): Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message */}
					{data.map(({ content }, index) => (
						<AccordionContentSection
							key={index}
							sectionLabel={
								sectionLabel &&
								sectionLabel + ' - ' + headings[index]
							}
							size={size}
							heading={headings[index]}
							htmlContent={content}
							disableTranslate={disableTranslate}
							trackSectionViews={trackSectionViews}
							trackSectionClick={trackSectionClick}
							isAccordionPanelOpen={isAccordionPanelOpen}
						/>
					))}
				</Conditional>
			</SummaryWrapper>
		);
	}
}

export default Summary;
